import Swiper from "swiper";

export default function PromoSlider(container) {
  // const sliderContainer = container.querySelector(".js-promo-slider-container");
  const nextButtonElement = container.querySelector(
    ".js-promo-slider-btn-next"
  );
  const previousButtonElement = container.querySelector(
    ".js-promo-slider-btn-prev"
  );
  const pagination = container.querySelector(".js-promo-slider-pagination");
  let slider;

  const sliderOptions = {
    speed: 400,
    slidesPerView: 1,
    slideClass: "js-promo-slider-slide",
    wrapperClass: "js-promo-slider-wrapper",
    navigation: {
      nextEl: nextButtonElement,
      prevEl: previousButtonElement
    },
    pagination: {
      el: pagination,
      clickable: true
    }
  };

  initSlider();

  function initSlider() {
    slider = new Swiper(container, sliderOptions);
    slider.init();
  }
}
