import svg4everybody from "svg4everybody";
import PromoSlider from "Components/PromoSlider";
import ButtonScrollTop from "Components/ButtonScrollTop";
import headerMenu from "Components/Header";

document.addEventListener("DOMContentLoaded", () => {
  svg4everybody();

  const promoSliderElement = document.querySelector(
    ".js-promo-slider-container"
  );
  if (promoSliderElement) {
    PromoSlider(promoSliderElement);
  }

  const buttonScrollTopElement = document.querySelector(
    ".js-button-scroll-top"
  );
  if (buttonScrollTopElement) {
    ButtonScrollTop(buttonScrollTopElement);
  }

  const headerWrapper = document.querySelector(".js-header__wrapper");
  if (headerWrapper) {
    headerMenu();
  }
});
