import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export default function headerMenu() {
  scrollMenu();
  openHamburger();

  function openHamburger() {
    const mobileWrapper = document.getElementsByClassName(
      "js-header__wrapper"
    )[0];
    const button = mobileWrapper.getElementsByClassName(
      "js-menu-mobile-hamburger"
    )[0];

    const classShow = "mobile-menu";
    const classOverflow = "overflow-hidden";

    addEvent();

    function addEvent() {
      button.addEventListener("change", jobMenu);
    }

    function jobMenu() {
      mobileWrapper.classList.toggle(classShow);
      document.body.classList.toggle(classOverflow);
    }
  }

  function scrollMenu() {
    const linkAbaut = document.getElementById("top-menu-abaut");
    const linkServices = document.getElementById("top-menu-services");
    const linkPartners = document.getElementById("top-menu-partners");

    linkAbaut.addEventListener("click", e => {
      const scrollTo = document.getElementById("about");

      e.preventDefault();
      window.scrollTo({
        top: calculateScrollEnd(scrollTo),
        left: 0,
        behavior: "smooth"
      });
    });

    linkServices.addEventListener("click", e => {
      const scrollTo = document.getElementById("services");

      e.preventDefault();
      window.scrollTo({
        top: calculateScrollEnd(scrollTo),
        left: 0,
        behavior: "smooth"
      });
    });

    linkPartners.addEventListener("click", e => {
      const scrollTo = document.getElementById("partners");

      e.preventDefault();
      window.scrollTo({
        top: calculateScrollEnd(scrollTo),
        left: 0,
        behavior: "smooth"
      });
    });

    function calculateScrollEnd(scrollTo) {
      const coordinateY = scrollTo.getBoundingClientRect().top;
      const currentScroll = window.scrollY;
      const heightScroll = coordinateY + currentScroll;

      return heightScroll;
    }
  }
}
