import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export default function ButtonScrollTop(button) {
  button.addEventListener("click", e => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  });
}
